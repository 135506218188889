<template>
  <div class="bed">
    <div class="title">
      <img src="@/assets/bed_images/bed.png" alt="" />
      可预约床位
      <span class="highlight">{{ todayEmpty }}</span>
      个
    </div>
    <div class="notice">
      <span class="highlight">注意：</span>
      可预约 {{ bedDay }} 日内床位与床位类型
    </div>
    <div class="bedlist">
      <ul>
        <li
          v-for="(item, index) in bedList"
          :key="index"
          @click="toConfirm(item)"
        >
          <van-image
            width="2.8rem"
            height="2.15rem"
            :src="require('@/assets/bed_images/' + item.bedType + '.jpg')"
          />
          <div class="des">
            {{ item.roomName }}-{{ item.bedCode }}
            <br />
            <van-tag plain round size="large" type="primary">
              {{ item.bedTypeText }}
            </van-tag>
          </div>
        </li>
      </ul>
    </div>
    <div style="height: 64px">
      <div class="buttonCation">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          @click="toConfirm"
        >
          立即预约
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
  import Base64 from '@/util/Base64'
  import { getBedInfo } from '@/api/his/his'
  import { Toast } from 'vant'

  export default {
    name: 'Index',
    data() {
      return {
        bedDay: null,
        todayOpen: null,
        todayEmpty: null,
        bedList: [],
      }
    },
    async created() {
      let data = JSON.parse(sessionStorage.getItem('day'))
      this.bedDay = data.bedDay
      await this.fetchData()
    },
    methods: {
      //获取床位列表
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在提交中...',
          forbidClick: true,
        })
        const { data } = await getBedInfo({})
        Toast.clear()
        this.bedList = data.bed_list
        this.todayOpen = data.todayOpen
        this.todayEmpty = data.todayEmpty
      },
      //预约确认
      toConfirm(item) {
        this.$router.push({
          path: '/bedConfirm',
          query: {
            bedType: Base64.encode(item.bedType),
            bedTypeText: Base64.encode(item.bedTypeText),
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .bed {
    width: 95%;
    margin: 0.4rem auto auto;
    font-size: 0.5rem;
    .title {
      padding: 0.5rem;
      background: #fff;
      margin-top: 0.35rem;
      font-weight: bold;
      border-radius: 10px;
      img {
        vertical-align: middle;
        width: 1rem;
      }
    }
    .notice {
      padding: 0.5rem;
      background: #fff;
      margin-top: 0.35rem;
      font-size: 0.4rem;
      border-radius: 10px;
      .highlight {
        color: rgb(255, 145, 0);
        font-size: 0.45rem;
      }
    }
    .highlight {
      color: rgb(255, 145, 0);
    }
    .bedlist {
      margin-top: 0.35rem;
      ul {
        a {
          color: #000;
        }
        li {
          padding: 0.5rem;
          background: #fff;
          margin-bottom: 0.35rem;
          border-radius: 10px;
          overflow: hidden;
          .van-image {
            border-radius: 5px;
            overflow: hidden;
            float: left;
          }
          .des {
            padding-top: 0.4rem;
            width: fit-content;
            float: left;
            overflow: hidden;
            font-size: 0.45rem;
            padding: 0.3rem;
            box-sizing: border-box;
          }
          .van-tag {
            margin-top: 0.2rem;
            width: 2rem;
            text-align: center;
            display: inherit;
          }
        }
      }
    }
    .buttonCation {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 10px 20px;
      box-sizing: border-box;
      background: #f2f4f6;
      left: 0;
    }
  }
</style>
