import { request } from '@/util/request'

export function getDoctorScheduleList(params) {
  return request({
    url: '/api/His/GetDoctorScheduleList',
    method: 'get',
    params,
  })
}

export function getScheduleDetailList(params) {
  return request({
    url: '/api/His/GetScheduleDetailList',
    method: 'get',
    params,
  })
}

export function getNumberScheduleList(params) {
  return request({
    url: '/api/His/GetNumberScheduleList',
    method: 'get',
    params,
  })
}

export function registerAppointment(data) {
  return request({
    url: '/api/His/RegisterAppointment',
    method: 'post',
    data,
  })
}

export function cancelAppointment(data) {
  return request({
    url: '/api/His/CancelAppointment',
    method: 'post',
    data,
  })
}

export function getAppointmentsList(params) {
  return request({
    url: '/api/His/GetAppointmentsList',
    method: 'get',
    params,
  })
}

export function getDoctorCloseList(params) {
  return request({
    url: '/api/His/GetDoctorCloseList',
    method: 'get',
    params,
  })
}

export function getOutpatientScheduleList(params) {
  return request({
    url: '/api/His/GetOutpatientScheduleList',
    method: 'get',
    params,
  })
}

export function getHospitalFeeList(params) {
  return request({
    url: '/api/His/GetHospitalFeeList',
    method: 'get',
    params,
  })
}

export function getHospitalPreFeeList(params) {
  return request({
    url: '/api/His/GetHospitalPreFeeList',
    method: 'get',
    params,
  })
}

export function getDrugList(params) {
  return request({
    url: '/api/His/GetDrugList',
    method: 'get',
    params,
  })
}

export function getProjectList(params) {
  return request({
    url: '/api/His/GetProjectList',
    method: 'get',
    params,
  })
}

export function getQueueList(params) {
  return request({
    url: '/api/His/GetQueueList',
    method: 'get',
    params,
  })
}

export function getPayList(params) {
  return request({
    url: '/api/His/GetPayList',
    method: 'get',
    params,
  })
}

export function getSeeDoctorList(params) {
  return request({
    url: '/api/His/GetSeeDoctorList',
    method: 'get',
    params,
  })
}

export function getCloudImageUrl(params) {
  return request({
    url: '/api/His/getCloudImageUrl',
    method: 'get',
    params,
  })
}

export function getBedInfo(params) {
  return request({
    url: '/api/His/GetBedInfo',
    method: 'get',
    params,
  })
}

export function confirmBed(data) {
  return request({
    url: '/api/His/ConfirmBed',
    method: 'post',
    data,
  })
}
